import React, { useState, useEffect, useRef } from 'react';
import "./styles.scss";
import Draggable from 'react-draggable';
import { Resizable } from "react-resizable";
import { AppButton, AppLoader, AppInput } from "../../../components"
import { sampleData } from '../../../components/AppEditorFields/sampleData';
import { useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getIconByName } from '../../../utilities/fontAwesomeIcons';
import useCacheBuster from '../../../components/AppTables/useCacheBuster';

const DataEntryFields = (props) => {
    const { field, label, value, style, isIcon, iconLabel, iconType, type, defaultValue, onChange, isErrorEmail, isErrorName, fieldName, togglePhotoModal, uploadFile, metaData, orientation, disabled = false } = props;
    const [cssContent, setCssContent] = useState('');
    const { cacheBuster } = useCacheBuster();
    const [image, setImage] = useState('https://lexicard-public.s3.amazonaws.com/namecards/logo/user_photo_holder.png')

    useEffect(() => {
        fetchFontUrls();
    }, []);

    useEffect(() => {
        loadImage();
    }, [uploadFile, metaData]);

    const loadImage = () => {
        if(metaData?.logo && uploadFile === ''){
            setImage(metaData.logo+'?'+cacheBuster)
        }else{
            if(!uploadFile){
                setImage('https://lexicard-public.s3.amazonaws.com/namecards/logo/user_photo_holder.png')
            }else{
                if(uploadFile){
                    setImage(URL.createObjectURL(uploadFile[0]));
                }else if(metaData?.logo){
                    setImage(metaData.logo+'?'+cacheBuster)
                }
            } 
        }
        
    }

    const fetchFontUrls = async () => {
        try {
            const fontText = 'https://lexicard-public.s3.ap-southeast-1.amazonaws.com/fonts/fonts.txt';
            const response = await fetch(fontText);

            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }

            const data = await response.text();
            const fontUrls = data.split('\n').map((line) => line.trim()).filter(Boolean);

            const fontFiles = fontUrls.map((fontUrl) => {
                return 'https://lexicard-public.s3.ap-southeast-1.amazonaws.com/fonts/' + fontUrl;
            });

            let cssContent = '';
            fontFiles.forEach((fontFile) => {
                const fontFilename = fontFile.split('/').pop();
                const fontFamily = fontFilename.replace(/\.[^/.]+$/, '');

                cssContent += `
                @font-face {
                    font-family: '${fontFamily}';
                    src: url('${fontFile}') format('truetype'),
                        /* Safari, Android, iOS */
                        url('${fontFile.replace(/\.[^/.]+$/, '.woff')}') format('woff');
                        /* Modern Browsers */
                }
                `;
            });

            setCssContent(cssContent);
        } catch (error) {
            console.error('Error:', error);
        }
    };

    let axisX = 0;
    let axisY = 0;
    let font = "";
    let alignment = "default";
    let fieldOrientation = "horizontal";
    let color = "black";
    let size = "16px";
    let weight = "normal";
    let transform = "none";
    let width = '100%';
    let height = '0px';
    let labelSize = "16px"
    let labelColor = "black"
    let labelWidth = field.labelStyle?.width ? field.labelStyle?.width + "px" : undefined

    axisX = style?.x;
    axisY = style?.y + 4;
    font = style?.font;

    alignment = style?.alignmentX;
    fieldOrientation = style?.orientation;
    color = style?.color;
    size = style?.size + "px";
    weight = style?.weight;
    transform = style?.textTransform;
    width = style?.width + "%";
    height = style?.height + "px";

    labelSize = field.labelStyle?.size + "px"
    labelColor = field.labelStyle?.color

    const icon = getIconByName(iconLabel);

    if (fieldOrientation === "vertical") {
        fieldOrientation = 'vertical-lr'
    } else {
        fieldOrientation = 'horizontal-tb'
    }

    // width = (width / 2) + width 
    // height = (height / 2) + height 

    const fieldStyle = {
        position: "absolute",
        top: 0,
        left: 0,
        right: 390,
        fontSize: size,
        color: color,
        fontWeight: weight,
        textTransform: transform,
        width: orientation === 'horizontal' ? null : '70%',
        bottom: 'auto',
        writingMode: fieldOrientation,
        textOrientation: 'upright',
        // height: height,
        fontFamily: font,
        wordWrap: 'break-word',
        textAlign: alignment
    }

    let plotX = field.style.x
    let plotY = field.style.y

    if(orientation === 'horizontal'){
        plotX = (plotX / 2) + plotX
        plotY = (plotY / 2) + plotY
    }
    

    const inputStyle = {
        fontSize: size,
        color: color,
        fontWeight: weight,
        textTransform: transform,
        // height: height,
        fontFamily: font,
        textAlign: alignment,
        wordWrap: 'break-word',
        border: '0px',
        borderBottom: field.field === 'name' ? isErrorName ? '1px solid red' : '1px solid lightgray' : field.field === 'email' ? isErrorEmail  ? '1px solid red' : '1px solid lightgray' : '1px solid lightgray',
        width: label ? plotX > 200 ? '50%' : '80%' : plotX > 200 ? '50%' : '100%',
        backgroundColor: 'rgba(255, 255, 255, 0.2)',
    }

    return (
        <>
            <style>
                {cssContent}
            </style>
            <Draggable
                position={{ x: plotX, y: plotY }}
                bounds={{ top: 0, left: 0}}
                onStart={() => false}
            >
                {field.type === "logo" && value ? (
                    <div
                        // onClick={() => togglePhotoModal()}
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 390,
                            bottom: 300,
                            width: width,
                            height: height,
                            backgroundSize: 'contain',
                            backgroundRepeat: 'no-repeat',
                            backgroundPosition: 'center',
                            backgroundImage: `url(${image})`,
                            border: '1px solid gray'
                        }}
                    ></div>
                ) : (
                    <div
                        style={fieldStyle}
                    >
                        {isIcon === true ? (
                            iconType === 'select' || iconType === undefined ? <React.Fragment>
                                {icon !== null ? <><FontAwesomeIcon icon={icon} style={{ fontSize: labelSize, color: labelColor }} /><span style={{ fontSize: labelSize, color: labelColor }}>: </span></> : null}
                            </React.Fragment> : iconType === 'url' || iconType === 'upload' ? iconLabel !== '' ? <>
                                <img
                                    src={iconLabel + '?' + cacheBuster}
                                    alt="Icon Label"
                                    style={{
                                        display: 'inline-block',
                                        width: labelSize,
                                        height: labelSize,
                                        objectFit: 'contain'
                                    }}
                                /><span style={{ fontSize: labelSize, color: labelColor }}>: </span>
                            </> : null : null
                        ) : (
                            label ? <span style={{ fontSize: labelSize, color: labelColor, width: labelWidth, display: 'inline-block' }}>{label} :</span> : null
                        )}
                        <input 
                            type={'text'}
                            placeholder={fieldName}
                            defaultValue={defaultValue}
                            pattern={type === 'number' ? "[0-9\s!@#$%^&*()-_=+{}\[\]:;<>,.?~\\/]*" : ''}
                            onChange={(e) => onChange(e.target.value)} 
                            style={inputStyle}
                            disabled={disabled}
                        />
                        {/* <textarea 
                            placeholder={fieldName}
                            defaultValue={defaultValue}
                            onChange={(e) => onChange(e.target.value)} 
                            style={inputStyle}
                            rows={1}
                        /> */}
                    </div>
                )}
            </Draggable>
        </>
    );
    
}

export default DataEntryFields;
