const version = "v1.6.149";

const ENV = {
    dev: {
        env: 'development',
        appVersion: `${version} [Dev]`,
        apiUrl: 'https://dev-corp.lexiscard.asia/api',
    },
    devlocal: {
        env: 'local',
        appVersion: `${version} [Dev Local]`,
        apiUrl: 'http://localhost:3000',
    },
    prod: {
        env: 'production',
        appVersion: version,
        apiUrl: 'https://corp.lexiscard.asia/api',
    },
}

export const getEnvVars = () => {
    let apiEndpoint = ENV.devlocal
    if (window.location.href.includes("localhost")) {
      apiEndpoint = ENV.devlocal
    }else{
        if(window.location.href.includes("dev-corp")){
            apiEndpoint = ENV.dev
        }else{
            apiEndpoint = ENV.prod
        }
    }
    return apiEndpoint;
};